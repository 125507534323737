import { useState } from 'react';
import industrialIcon from "../assets/images/industrial-icon.svg";
import automotiveIcon from "../assets/images/automotive-icon.svg";
import healthInsuranceIcon from "../assets/images/health-insurance-icon.svg";
import foodDrinks from "../assets/images/food-and-drink-icon.svg";
import petFood from "../assets/images/pet-food-icons.svg";
import plastics from "../assets/images/plastics-icon.svg";
import ecommerce from "../assets/images/ecommerce-icon.svg";
import retail from "../assets/images/retail-icon.svg";
import chemicals from "../assets/images/chemicals_furniture_icon.svg";
import furniture from "../assets/images/furniture_icon.png";
import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';


const IndustryWeServe = () => {
    const [currentSlide, setCurrentSlide] = useState(0)
    const [loaded, setLoaded] = useState(false)

    const [sliderRef, instanceRef] = useKeenSlider({

        breakpoints: {
            "(min-width: 640px)": {
                slides: { perView: 5, spacing: 1 },
            },
            "(min-width: 1000px)": {
                slides: { perView: 5, spacing: 10 },
            },
        },
        slides: { perView: 2, centered: true, spacing: 15 },
        slideChanged(slider) {
            setCurrentSlide(slider.track.details.rel)
        },
        created() {
            setLoaded(true)
        },
        loop: true,
        renderMode: 'performance',
        mode: "free",
        initial: 0,
    })

    const handlePrev = () => {
        if (instanceRef.current) {
            instanceRef.current.prev();
        }
    };

    const handleNext = () => {
        if (instanceRef.current) {
            instanceRef.current.next();
        }
    };

    const classNames = (...classes) => {
        return classes.filter(Boolean).join(' ');
    }
    return <div className='container mx-auto'>
        <h3 className='mt-10 text-center font-open-sans font-extrabold text-3xl leading-tight text-black-900 md:text-black-800 lg:text-black-700 xl:text-5xl xl:leading-[64px] sm:text-xl sm:leading-normal sm:text-black-700'>
            Industries We Serve
        </h3>
        <div className="p-4  mx-auto px-5 md:px-0 relative md:w-3/4 mb-20">
            <div className=" text-gray-70 relative z-10 md:rounded-full  rounded-3xl w-full border border-white border-opacity-10 bg-white/50 py-7 md:px-6 shadow-outer backdrop-blur-outer">
                <div ref={sliderRef} className='keen-slider'>
                    <div className='keen-slider__slide bg-white'>
                        <div className='mx-auto w-24 h-24 flex justify-center items-center rounded-full bg-white/40 drop-shadow-xl p-1'>
                            <div className="bg-[#FFF] w-20 h-20  flex justify-center items-center rounded-full">
                                <img src={industrialIcon} alt="Icon 1" className="w-10 h-10" />
                            </div>
                        </div>
                        <p className='md:font-bold text-gray-800 mt-1 text-center text-sm font-open-sans font-semibold leading-tight'>
                            Industrial
                        </p>
                    </div>
                    <div className='keen-slider__slide'>
                        <div className='mx-auto w-24 h-24 flex justify-center items-center rounded-full bg-white/40 drop-shadow-xl p-1'>
                            <div className="bg-[#FFF] w-20 h-20  flex justify-center items-center rounded-full">
                                <img src={healthInsuranceIcon} alt="Icon 1" className="w-10 h-10" />
                            </div>
                        </div>
                        <p className='md:font-bold  mt-1 text-center'>
                            Medical
                        </p>
                    </div>
                    <div className='keen-slider__slide'>
                        <div className='mx-auto w-24 h-24 flex justify-center items-center rounded-full bg-white/40 drop-shadow-xl p-1'>
                            <div className="bg-[#FFF] w-20 h-20  flex justify-center items-center rounded-full">
                                <img src={petFood} alt="Icon 1" className="w-10 h-10" />
                            </div>
                        </div>
                        <p className='md:font-bold text-gray-800 mt-1 text-center text-sm font-open-sans font-semibold leading-tight'>
                            Pet Goods
                        </p>
                    </div>
                    <div className='keen-slider__slide'>
                        <div className='mx-auto w-24 h-24 flex justify-center items-center rounded-full bg-white/40 drop-shadow-xl p-1'>
                            <div className="bg-[#FFF] w-20 h-20  flex justify-center items-center rounded-full">
                                <img src={foodDrinks} alt="Icon 1" className="w-10 h-10" />
                            </div>
                        </div>
                        <p className='md:font-bold text-gray-800 mt-1 text-center text-sm font-open-sans font-semibold leading-tight'>
                            Food & Drink
                        </p>
                    </div>
                    <div className='keen-slider__slide'>
                        <div className='mx-auto w-24 h-24 flex justify-center items-center rounded-full bg-white/40 drop-shadow-xl p-1'>
                            <div className="bg-[#FFF] w-20 h-20  flex justify-center items-center rounded-full">
                                <img src={automotiveIcon} alt="Icon 1" className="w-10 h-10" />
                            </div>
                        </div>
                        <p className='md:font-bold text-gray-800 mt-1 text-center text-sm font-open-sans font-semibold leading-tight'>
                            Automotive
                        </p>
                    </div>
                    <div className='keen-slider__slide'>
                        <div className='mx-auto w-24 h-24 flex justify-center items-center rounded-full bg-white/40 drop-shadow-xl p-1'>
                            <div className="bg-[#FFF] w-20 h-20  flex justify-center items-center rounded-full">
                                <img src={plastics} alt="Icon 1" className="w-10 h-10" />
                            </div>
                        </div>
                        <p className='md:font-bold text-gray-800 mt-1 text-center text-sm font-open-sans font-semibold leading-tight'>
                            Plastics
                        </p>
                    </div>
                    <div className='keen-slider__slide'>
                        <div className='mx-auto w-24 h-24 flex justify-center items-center rounded-full bg-white/40 drop-shadow-xl p-1'>
                            <div className="bg-[#FFF] w-20 h-20  flex justify-center items-center rounded-full">
                                <img src={ecommerce} alt="Icon 1" className="w-10 h-10" />
                            </div>
                        </div>
                        <p className='md:font-bold text-gray-800 mt-1 text-center text-sm font-open-sans font-semibold leading-tight'>
                            Ecommerce
                        </p>
                    </div>
                    <div className='keen-slider__slide'>
                        <div className='mx-auto w-24 h-24 flex justify-center items-center rounded-full bg-white/40 drop-shadow-xl p-1'>
                            <div className="bg-[#FFF] w-20 h-20  flex justify-center items-center rounded-full">
                                <img src={chemicals} alt="Icon 1" className="w-10 h-10" />
                            </div>
                        </div>
                        <p className='md:font-bold text-gray-800 mt-1 text-center text-sm font-open-sans font-semibold leading-tight'>
                            Chemicals
                        </p>
                    </div>
                    <div className='keen-slider__slide'>
                        <div className='mx-auto w-24 h-24 flex justify-center items-center rounded-full bg-white/40 drop-shadow-xl p-1'>
                            <div className="bg-[#FFF] w-20 h-20  flex justify-center items-center rounded-full">
                                <img src={furniture} alt="Icon 1" className="w-10 h-10" />
                            </div>
                        </div>
                        <p className='md:font-bold text-gray-800 mt-1 text-center text-sm font-open-sans font-semibold leading-tight'>
                            Furniture
                        </p>
                    </div>
                    <div className='keen-slider__slide'>
                        <div className='mx-auto w-24 h-24 flex justify-center items-center rounded-full bg-white/40 drop-shadow-xl p-1'>
                            <div className="bg-[#FFF] w-20 h-20  flex justify-center items-center rounded-full">
                                <img src={retail} alt="Icon 1" className="w-10 h-10" />
                            </div>
                        </div>
                        <p className='md:font-bold text-gray-800 mt-1 text-center text-sm font-open-sans font-semibold leading-tight'>
                            Retail
                        </p>
                    </div>
                </div>
            </div>
            {/* <!-- Next Button --> */}
            {loaded && instanceRef.current && (
                <><button disabled={currentSlide === instanceRef.current.track.details.slides.length - 1} className={classNames(
                    currentSlide === instanceRef.current.track.details.slides.length - 1 ? "bg-blue-500" : "bg-blue-500", "absolute  hover:bg-blue-700 w-1 text-white font-bold py-2 px-4   md:block top-1/2 transform -translate-y-1/2 md:right-0 right-4 z-10 rounded-md")} onClick={handleNext}>
                    <svg className="w-6 h-6 -ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path>
                    </svg>
                </button>
                    <button disabled={currentSlide === 0} className={classNames(
                        currentSlide === 0 ? "bg-blue-500" : "bg-blue-500", "absolute text-white md:block rounded-md focus:outline-none border-blue-300 text-gray-600 hover:border-blue-400 hover:bg-blue-500 focus-visible:outline-2  w-1 font-bold py-2 px-4  top-1/2 transform -translate-y-1/2 md:left-0 left-4 z-10")} onClick={handlePrev}>
                        <svg className="w-6 h-6 -ml-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7"></path>
                        </svg>
                    </button></>
            )
            }
            {/* bg overlay inner */}
            <div className="absolute inset-0  md:rounded-full rounded-3xl md:w-11/12 mx-auto w-10/12  h-5/6 isolate bg-white/50 box-shadow-inner py-7 px-6 md:mt-10 mt-20"></div>

        </div>
    </div>
}

export default IndustryWeServe;