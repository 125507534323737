
export default function StepsCard({ step, title, description }) {
    return (
        <div className={`bg-white rounded-lg hover:shadow-outline text-center shadow-normal md:p-6 p-3 md:w-1/4 w-5/12 justify-center items-center border-slate-500 md:h-[206px]`}>
            <h2 className="text-xl sm:text-2xl font-semibold mb-2 text-blue-500">0{step}</h2>
            <p className="text-gray-800 md:text-[20px] text-base font-semibold text-center md:leading-[34.462px] font-open-sans">{title}</p>
            <p className="text-gray-800 text-base font-normal text-start md:text-center font-open-sans hidden md:block">{description}</p>
        </div>
    )
}
