import { combineReducers } from "redux";
import { boxProperty, RESET_STATE } from "./boxproperties";
const ADD_ITEM = "ADD_ITEM";
const UPDATE_ITEM = "UPDATE_ITEM";
export function addItem(item) {
  return {
    type: ADD_ITEM,
    item,
  };
}

export function updateItem(item) {
  return {
    type: UPDATE_ITEM,
    item,
  };
}

const defaultItems = [];

function items(state = defaultItems, action) {
  const { item } = action;
  let items;

  switch (action.type) {
    case ADD_ITEM:
      items = state.filter((b) => item.code !== b.code);
      const getItem = state.find((b) => item.code === b.code);
      if (getItem) {
        getItem.count = getItem.count + 1 || 1;
        return [...items, getItem];
      } else {
        item.count = 1;
      }
      return [...state, item];
    case UPDATE_ITEM:
      items = state.filter((b) => item.code !== b.code);
      const getItemOld = state.find((b) => item.code === b.code);
      item.count = getItemOld.count;
      return [...items, item];
    default:
      return state;
  }
}

const allReducers = combineReducers({
  items,
  boxProperty,
});

const rootReducer = (state, action) => {
  if (action.type === RESET_STATE) {
    state = {};
  }

  return allReducers(state, action);
};

export default rootReducer;
