// import { NavLink } from 'react-router-dom';
// import RHeader from './RHeader';

// const RHero = () => {
//     return (
//         <section className="md:bg-cover bg-contain md:bg-center bg-right bg-no-repeat  relative pb-5 md:h-[43rem] w-full home-hero-gradient-bg">
//             <video autoPlay muted loop className="absolute z-0 w-full h-full object-cover">
//                 <source src="https://res.cloudinary.com/nazehs/video/upload/f_auto:video,q_auto/v1/rightbox/jznurx7e2jxcy0tujski" type="video/mp4" />
//                 Your browser does not support the video tag.
//             </video>
//             <RHeader newPage={false} />
//             <div className="container mx-auto md:mx-start text-start md:text-left relative z-10 pt-10">
//                 <div className='w-full md:w-2/3 md:ps-28 px-10 ps-4 md:pt-[8%]'>
//                     <div className="text-lg md:text-4xl font-open-sans md:font-bold font-semibold text-white md:mb-8 mb-4">
//                         <p className="mb-0 md:mb-6">Protect Your <span className='text-[#138DFF]'>Products</span></p>
//                         <p className="mb-0 md:mb-6">and the <span className='text-[#5CDB59]'>Planet</span></p>
//                         <p className="mb-0 md:mb-6">with bespoke Cardboard Boxes</p>
//                         <p className="mb-0 md:mb-6">from <span className='text-[#138DFF]'>RightBox.</span></p>
//                         <p className="mb-0 md:mb-6 text-base md:w-[56%] w-full">We deliver sustainability. Your trusted partner in bespoke cardboard packaging design & manufacture</p>
//                     </div>

//                     <div className="flex items-center">
//                         <NavLink to="/request"
//                             className="px-4 py-2 bg-[#138DFF] rounded-[90px] hover:border-blue-500 text-white transition duration-300 flex items-center space-x-2"
//                         >
//                             <svg xmlns="http://www.w3.org/2000/svg" width="35" className="w-4 h-4" height="41" viewBox="0 0 35 41" fill="none">
//                                 <path d="M17.4987 22.2266V39.7228L5.17188 35.0093V24.4486" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
//                                 <path d="M17.4973 22.2252L5.29398 17.5117L1.84375 22.5243L14.0471 27.2377L17.4973 22.2252Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
//                                 <path d="M17.5 22.2266V39.7228L29.8269 35.0093V24.5271" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
//                                 <path d="M17.5 22.2252L29.7034 17.5117L33.1536 22.5243L20.9502 27.2377L17.5 22.2252Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
//                                 <path d="M5.29688 17.5103L17.5002 12.7969L29.7036 17.5103" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
//                                 <path d="M17.503 8.83768L15.8543 9.42377C15.1706 9.66682 14.4559 9.14775 14.4759 8.4226L14.5241 6.67393L13.457 5.28752C13.0145 4.7126 13.2875 3.87268 13.9835 3.6676L15.662 3.17299L16.6512 1.73002C17.0614 1.13166 17.9448 1.13166 18.3549 1.73002L19.3441 3.17299L21.0226 3.6676C21.7186 3.87275 21.9916 4.7126 21.5491 5.28752L20.482 6.67393L20.5302 8.4226C20.5501 9.14775 19.8355 9.66682 19.1518 9.42377L17.503 8.83768Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
//                                 <path d="M28.7138 12.2219L27.5239 12.6449C26.8402 12.8879 26.1255 12.3688 26.1455 11.6437L26.1802 10.3817L25.4102 9.38111C24.9677 8.80619 25.2406 7.96627 25.9366 7.76119L27.148 7.40424L27.8619 6.36283C28.2721 5.76447 29.1555 5.76447 29.5656 6.36283L30.2795 7.40424L31.4909 7.76119C32.1869 7.96635 32.4598 8.80619 32.0173 9.38111L31.2473 10.3817L31.282 11.6437C31.3019 12.3688 30.5873 12.8879 29.9036 12.6449L28.7138 12.2219Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
//                                 <path d="M6.28406 12.2219L5.09422 12.6449C4.41055 12.8879 3.69586 12.3688 3.71586 11.6437L3.75055 10.3817L2.98047 9.38111C2.53797 8.80619 2.81094 7.96627 3.50695 7.76119L4.71828 7.40424L5.43219 6.36283C5.84242 5.76447 6.72578 5.76447 7.13594 6.36283L7.84985 7.40424L9.06117 7.76119C9.75719 7.96635 10.0302 8.80619 9.58766 9.38111L8.81758 10.3817L8.85227 11.6437C8.87219 12.3688 8.15758 12.8879 7.47391 12.6449L6.28406 12.2219Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
//                             </svg>
//                             <span className="bg-[#138DFF] text-white rounded-full transition duration-300 ease-in-out inline-block font-semibold text-sm">
//                                 Build Your Box
//                             </span>
//                         </NavLink>
//                     </div>

//                 </div>
//             </div>
//         </section>
//     );
// };

// export default RHero;
import { NavLink } from 'react-router-dom';
import RHeader from './RHeader';
import fallBackImage from "../assets/images/hero-bg.svg";

const RHero = () => {
    return (
        <section className="relative pb-5 md:h-[43rem] w-full home-hero-gradient-bg">
            <video autoPlay muted loop className="absolute z-0 w-full h-full object-cover" poster={fallBackImage}>
                <source src="https://res.cloudinary.com/nazehs/video/upload/f_auto:video,q_auto/v1/rightbox/jznurx7e2jxcy0tujski" type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-black opacity-60 -z-2"></div>
            <RHeader newPage={false} />
            <div className="container mx-auto md:mx-start text-start md:text-left relative z-10 pt-10">
                <div className='w-full md:w-2/3 md:ps-28 px-10 ps-4 md:pt-[8%]'>
                    <div className="text-lg md:text-4xl font-open-sans md:font-bold font-semibold text-white md:mb-8 mb-4">
                        <p className="mb-0 md:mb-6">Protect Your <span className='text-[#138DFF]'>Products</span></p>
                        <p className="mb-0 md:mb-6">and the <span className='text-[#5CDB59]'>Planet</span></p>
                        <p className="mb-0 md:mb-6">with bespoke Cardboard Boxes</p>
                        <p className="mb-0 md:mb-6">from <span className='text-[#138DFF]'>RightBox.</span></p>
                        <p className="mb-0 md:mb-6 text-base md:w-[56%] w-full">We deliver sustainability. Your trusted partner in bespoke cardboard packaging design & manufacture</p>
                    </div>

                    <div className="flex items-center">
                        <NavLink to="/request"
                            className="px-4 py-2 bg-[#138DFF] rounded-[90px] hover:border-blue-500 text-white transition duration-300 flex items-center space-x-2"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="35" className="w-4 h-4" height="41" viewBox="0 0 35 41" fill="none">
                                <path d="M17.4987 22.2266V39.7228L5.17188 35.0093V24.4486" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M17.4973 22.2252L5.29398 17.5117L1.84375 22.5243L14.0471 27.2377L17.4973 22.2252Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M17.5 22.2266V39.7228L29.8269 35.0093V24.5271" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M17.5 22.2252L29.7034 17.5117L33.1536 22.5243L20.9502 27.2377L17.5 22.2252Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M5.29688 17.5103L17.5002 12.7969L29.7036 17.5103" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M17.503 8.83768L15.8543 9.42377C15.1706 9.66682 14.4559 9.14775 14.4759 8.4226L14.5241 6.67393L13.457 5.28752C13.0145 4.7126 13.2875 3.87268 13.9835 3.6676L15.662 3.17299L16.6512 1.73002C17.0614 1.13166 17.9448 1.13166 18.3549 1.73002L19.3441 3.17299L21.0226 3.6676C21.7186 3.87275 21.9916 4.7126 21.5491 5.28752L20.482 6.67393L20.5302 8.4226C20.5501 9.14775 19.8355 9.66682 19.1518 9.42377L17.503 8.83768Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M28.7138 12.2219L27.5239 12.6449C26.8402 12.8879 26.1255 12.3688 26.1455 11.6437L26.1802 10.3817L25.4102 9.38111C24.9677 8.80619 25.2406 7.96627 25.9366 7.76119L27.148 7.40424L27.8619 6.36283C28.2721 5.76447 29.1555 5.76447 29.5656 6.36283L30.2795 7.40424L31.4909 7.76119C32.1869 7.96635 32.4598 8.80619 32.0173 9.38111L31.2473 10.3817L31.282 11.6437C31.3019 12.3688 30.5873 12.8879 29.9036 12.6449L28.7138 12.2219Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M6.28406 12.2219L5.09422 12.6449C4.41055 12.8879 3.69586 12.3688 3.71586 11.6437L3.75055 10.3817L2.98047 9.38111C2.53797 8.80619 2.81094 7.96627 3.50695 7.76119L4.71828 7.40424L5.43219 6.36283C5.84242 5.76447 6.72578 5.76447 7.13594 6.36283L7.84985 7.40424L9.06117 7.76119C9.75719 7.96635 10.0302 8.80619 9.58766 9.38111L8.81758 10.3817L8.85227 11.6437C8.87219 12.3688 8.15758 12.8879 7.47391 12.6449L6.28406 12.2219Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <span className="bg-[#138DFF] text-white rounded-full transition duration-300 ease-in-out inline-block font-semibold text-sm">
                                Build Your Box
                            </span>
                        </NavLink>
                    </div>

                </div>
            </div>
        </section>
    );
};

export default RHero;
