const DefaultButton = ({ label, onClick, type }) => {
  const primaryColor = '#138DFF';
  const primaryHoverColor = '#138DFF';

  return (
    <button
      className={`min-w-[273px] min-h-[50px] text-lg text-center rounded-lg border ${type === 'primary' ? `bg-[${primaryColor}] text-white hover:bg-${primaryHoverColor}` : `border-${primaryColor} text-${primaryColor}`} focus:outline-none focus:ring-2 focus:ring-${primaryColor} focus:ring-opacity-50`}
      onClick={onClick}
    >
      {label}
    </button>
  );
};

export default DefaultButton;
