import MaintenancePage from "./components/MaintenancePage";
import { RouterProvider } from "react-router-dom";
import { router } from './router';
import { Suspense } from 'react';

function App() {
  return (
    <main className="App">
      {process.env.REACT_APP_MAINTENANCE_MODE === 'true' ? (
        <MaintenancePage />
      ) : (
        <Suspense>
          <RouterProvider router={router} fallbackElement={<p className='text-4xl font-open-sans font-semibold text-blue-500'>Loading...</p>} />
        </Suspense>
      )}
    </main>
  );
}

export default App;
