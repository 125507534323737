const ADD_PROPERTY = "ADD_PROPERTY";
export const RESET_STATE = "RESET_STATE";
export function addObjectProperty(object) {
  return {
    type: ADD_PROPERTY,
    object,
  };
}

const defaultObj = {};

export const boxProperty = (state = defaultObj, action) => {
  switch (action.type) {
    case ADD_PROPERTY:
      return { ...state, ...action.object };
    default:
      return state;
  }
};
