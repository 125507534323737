export default function SolutionCard(item) {
    const needsShadow = (title) => {
        return title.toLowerCase().includes("pallet");
    };

    return (
        <div className="flex flex-col md:w-1/5 w-5/12 md:p-4 p-3 overflow-hidden border-gray-200 shadow-card bg-white rounded-2xl">
            <img
                src={item.imageUrl}
                alt={`${item.title} box`}
                loading="lazy"
                className={`md:h-48 object-fit h-28 self-stretch rounded-2xl ${needsShadow(item.title) ? "shadow-img" : ""}`} // Add a conditional shadow class
            />
            <div className="md:px-2 md:pt-4 pt-2 flex-grow">
                <div className="text-[#28305C] font-open-sans md:font-semibold md:text-base text-sm leading-normal not-italic  md:leading-[13.371px] text-center">
                    {item.title}
                </div>
                <p className="text-[#47475A] text-center font-manrope md:text-base text-xs not-italic leading-5 font-normal md:leading-[23.44px] pt-3 pb-5">
                    {item.description}
                </p>
            </div>
        </div>
    )
}


