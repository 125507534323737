import conventionalPackagingBox from '../assets/images/conventional-packaging-box.png';
import palletBox from '../assets/images/pallet-box.png';
import heavyDutyBox from '../assets/images/heavy-duty-box.png';
import largeBox from '../assets/images/large-box.png';
import bespokeBox from '../assets/images/bespoke-box.png';
import retailPackagingBox from '../assets/images/retail-packaging-box.png';
import drinksPackagingBox from '../assets/images/drinks-packaging-box.png';
import ecommercePackagingBox from '../assets/images/ecommerce-packaging-box.png';
import SolutionCard from './SolutionCard';
const bespokeSolutions = [
    {
        imageUrl: conventionalPackagingBox,
        title: "Conventional Corrugated",
        description: "Our corrugated shipping boxes offer an effective, sustainable, and affordable packaging solution. Manufactured bespoke to your needs, these boxes are designed to perfectly fit your products and offer optimal protection during transit.",
        linkUrl: "https://example.com/link1"
    },
    {
        imageUrl: palletBox,
        title: "Pallet Boxes",
        description: "Our expertly designed pallet boxes are built for efficient bulk storage, reducing handling and keeping your products safe.They are fully customisable - from height and base alternatives to reinforcements and options to print.",
        linkUrl: "https://example.com/link1"
    },
    {
        imageUrl: heavyDutyBox,
        title: "Heavy Duty",
        description: "Our Heavy-Duty boxes are designed to handle the most demanding shipping and storage needs. Crafted from heavy-duty double and triple wall material, with options of reinforced construction, these boxes provide unparalleled protection for your products.",
        linkUrl: "https://example.com/link1"
    },
    {
        imageUrl: largeBox,
        title: "Large Format",
        description: "Our Large Format boxes are the perfect solution for oversized or awkwardly shaped items. These robust boxes are expertly manufactured to accommodate your largest products, offering superior protection during transport or storage.",
        linkUrl: "https://example.com/link2"
    },
    {
        imageUrl: bespokeBox,
        title: "Bespoke Diecut",
        description: "Our bespoke die-cut cases can be based on existing FEFCO styles or designed to your specific requirements. We offer a range of styles including crash-lock cases, four-corner trays and multi-point glued cases to ensure optimal protection.",
        linkUrl: "https://example.com/link3"
    },
    {
        imageUrl: retailPackagingBox,
        title: "Retail Packaging",
        description: "Our retail packaging solutions offer superior protection during storage and shipping, all while maximizing your brand impact on store shelves. Elevate your brand and capture customer attention with our premium retail packaging solutions.",
        linkUrl: "https://example.com/link2"
    },
    {
        imageUrl: drinksPackagingBox,
        title: "Drinks Packaging",
        description: "Our bespoke beverage boxes are designed to optimise storage, ensure product protection, and elevate your brand. We can create a range of sustainable solutions including single packs, multi packs and subscription boxes",
        linkUrl: "https://example.com/link3"
    },
    {
        imageUrl: ecommercePackagingBox,
        title: "E-commerce Packaging",
        description: "Our Ecommerce solutions ensure your products arrive damage-free and turn every delivery into a brand experience. They are custom made to your requirements with options to print to promote your brand.",
        linkUrl: "https://example.com/link2"
    },
];

export default function BespokeSolutionsComponent() {
    return (<div className="flex flex-wrap mx-auto justify-center gap-3 md:gap-6 md:mb-6 mt-10 ">
        {
            bespokeSolutions.map((item, index) => (<SolutionCard {...item} key={index} />))
        }
    </div>)
}