import { NavLink } from 'react-router-dom';

export default function BusinessCard(item) {
    return (
        <div className='relative w-full mx-auto z-20 overflow-hidden rounded-3xl'>
            <div className='absolute inset-0 bg-gradient-to-b  opacity-80'>
                <div className='text-center w-full px-10 md:px-0 md:mt-36 mt-6'>
                    <h3 className='md:text-4xl md:font-bold text-lg text-white md:mb-4'>Find The <span className='text-blue-500'>RightBox</span> For Your Business</h3>
                    <p className='md:text-lg text-xs text-gray-300 md:mb-6 max-w-4xl mx-auto px-2'>
                        Get in touch to discover how RightBox can optimise your packaging costs and elevate your brand
                    </p>
                    <div className='mx-auto w-1/2 text-center flex justify-center items-center md:mt-20 mt-4'>
                        <NavLink to="/request"
                            className='px-4 py-2 bg-[#138DFF] rounded-[90px] hover:border-blue-500 text-white transition duration-300 flex items-center justify-center space-x-2'
                        >
                            <span className="text-white font-montserrat md:text-base text-xs md:font-semibold tracking-wide md:uppercase capitalize">
                                Get Started
                            </span>
                        </NavLink>
                    </div>
                </div>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" width="138" height="182" viewBox="0 0 138 182" fill="none" className='absolute left-0 ml-10   -z-10 hidden md:block fill-current bottom-64'>
                <path d="M3.00005 176C4.65691 176 6.00005 177.343 6.00005 179C6.00005 180.656 4.65691 182 3.00005 182C1.3432 182 5.55351e-05 180.656 5.55351e-05 179C5.55351e-05 177.343 1.3432 176 3.00005 176Z" fill="#138DFF" />
                <path d="M3 132C4.65685 132 6 133.344 6 135C6 136.657 4.65685 138 3 138C1.34315 138 0 136.657 0 135C0 133.344 1.34315 132 3 132Z" fill="#69769C" />
                <path d="M3 88C4.65685 88 6 89.3432 6 91C6 92.6569 4.65685 94 3 94C1.34315 94 0 92.6569 0 91C0 89.3432 1.34315 88 3 88Z" fill="#69769C" />
                <path d="M3 44.0004C4.65685 44.0004 6 45.3435 6 47.0004C6 48.6572 4.65685 50.0004 3 50.0004C1.34315 50.0004 0 48.6572 0 47.0004C0 45.3435 1.34315 44.0004 3 44.0004Z" fill="#69769C" />
                <path d="M3 0C4.65685 0 6 1.34315 6 3C6 4.65685 4.65685 6 3 6C1.34315 6 0 4.65685 0 3C0 1.34315 1.34315 0 3 0Z" fill="#69769C" />
                <path d="M47 176C48.6568 176 50 177.343 50 179C50 180.657 48.6568 182 47 182C45.3431 182 44 180.657 44 179C44 177.343 45.3431 176 47 176Z" fill="#138DFF" />
                <path d="M47 132C48.6568 132 50 133.344 50 135C50 136.657 48.6568 138 47 138C45.3431 138 44 136.657 44 135C44 133.344 45.3431 132 47 132Z" fill="#69769C" />
                <path d="M47 88C48.6568 88 50 89.3432 50 91C50 92.6569 48.6568 94 47 94C45.3431 94 44 92.6569 44 91C44 89.3432 45.3431 88 47 88Z" fill="#69769C" />
                <path d="M47 44.0004C48.6568 44.0004 50 45.3435 50 47.0004C50 48.6572 48.6568 50.0004 47 50.0004C45.3431 50.0004 44 48.6572 44 47.0004C44 45.3435 45.3431 44.0004 47 44.0004Z" fill="#69769C" />
                <path d="M47 0C48.6568 0 50 1.34315 50 3C50 4.65685 48.6568 6 47 6C45.3431 6 44 4.65685 44 3C44 1.34315 45.3431 0 47 0Z" fill="#69769C" />
                <path d="M91 176C92.6568 176 94 177.343 94 179C94 180.657 92.6568 182 91 182C89.3431 182 88 180.657 88 179C88 177.343 89.3431 176 91 176Z" fill="#138DFF" />
                <path d="M91 132C92.6568 132 94 133.344 94 135C94 136.657 92.6568 138 91 138C89.3431 138 88 136.657 88 135C88 133.344 89.3431 132 91 132Z" fill="#69769C" />
                <path d="M91 88C92.6568 88 94 89.3432 94 91C94 92.6569 92.6568 94 91 94C89.3431 94 88 92.6569 88 91C88 89.3432 89.3431 88 91 88Z" fill="#69769C" />
                <path d="M91 44.0004C92.6568 44.0004 94 45.3435 94 47.0004C94 48.6572 92.6568 50.0004 91 50.0004C89.3431 50.0004 88 48.6572 88 47.0004C88 45.3435 89.3431 44.0004 91 44.0004Z" fill="#69769C" />
                <path d="M91 0C92.6568 0 94 1.34315 94 3C94 4.65685 92.6568 6 91 6C89.3431 6 88 4.65685 88 3C88 1.34315 89.3431 0 91 0Z" fill="#69769C" />
                <path d="M135 176C136.657 176 138 177.343 138 179C138 180.657 136.657 182 135 182C133.343 182 132 180.657 132 179C132 177.343 133.343 176 135 176Z" fill="#138DFF" />
                <path d="M135 132C136.657 132 138 133.344 138 135C138 136.657 136.657 138 135 138C133.343 138 132 136.657 132 135C132 133.344 133.343 132 135 132Z" fill="#69769C" />
                <path d="M135 88C136.657 88 138 89.3432 138 91C138 92.6569 136.657 94 135 94C133.343 94 132 92.6569 132 91C132 89.3432 133.343 88 135 88Z" fill="#69769C" />
                <path d="M135 44.0004C136.657 44.0004 138 45.3435 138 47.0004C138 48.6572 136.657 50.0004 135 50.0004C133.343 50.0004 132 48.6572 132 47.0004C132 45.3435 133.343 44.0004 135 44.0004Z" fill="#69769C" />
                <path d="M135 0C136.657 0 138 1.34315 138 3C138 4.65685 136.657 6 135 6C133.343 6 132 4.65685 132 3C132 1.34315 133.343 0 135 0Z" fill="#69769C" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1248 710" fill="current" className='w-full h-auto rounded-3xl overflow-hidden'>
                <path d="M100.617 87.6953C100.617 62.8425 120.764 42.6953 145.617 42.6953H1102.39C1127.24 42.6953 1147.39 62.8425 1147.39 87.6953V365.507C1147.39 385.538 1134.15 403.158 1114.91 408.73L628.966 549.476C620.671 551.879 611.859 551.844 603.583 549.375L132.752 408.91C113.685 403.221 100.617 385.686 100.617 365.788L100.617 87.6953Z" fill="url(#gradientOverlay)" />
                <defs>
                    <linearGradient id="gradientOverlay" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="0%" stopColor="#0D101C" stopOpacity="0.8" />
                        <stop offset="100%" stopColor="#0D101C" stopOpacity="0.8" />
                    </linearGradient>
                </defs>
            </svg>

        </div>
    );
}
