const ErrorPage = () => {
    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <div className="container mx-auto text-center">
                <div className="max-w-md mx-auto bg-white rounded-lg shadow-md overflow-hidden md:max-w-lg">
                    <div className="md:flex">
                        <div className="w-full p-4">
                            <div className="mb-4">
                                <svg className="mx-auto w-12 h-12 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path></svg>
                            </div>
                            <h2 className="text-xl font-medium text-gray-800">Oops! Something went wrong.</h2>
                            <p className="text-sm text-gray-600 mt-4">
                                We're having trouble processing your request. Please try again later or contact support if the problem persists.
                            </p>
                            <div className="mt-6">
                                <button onClick={() => window.location.href = '/'} className="px-4 py-2 leading-5 text-white transition-colors duration-200 transform bg-gray-800 rounded hover:bg-gray-600 focus:outline-none focus:bg-gray-600">Go Home</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ErrorPage;
