import React, { useState } from 'react';
import { PostRequest } from '../services/Request';
import RHeader from '../shared/RHeader';
import Datepicker from 'react-tailwindcss-datepicker';
import DefaultButton from '../shared/Button';

const BookConsultationComponent = () => {
  const [status, setStatus] = useState(false);
  const [formValues, setFormValues] = useState({
    serviceType: 'Initial call',
    fullName: '',
    phone: '',
    email: '',
    notes: '',
  });
  const [formErrors, setFormErrors] = useState({});

  const [dateRange, setDateRange] = useState({
    from: new Date(),
    to: new Date().setMonth(11),
  });

  const validate = (values) => {
    const errors = {};
    if (!values.fullName) errors.fullName = 'Full name is required';
    if (!values.phone) errors.phone = 'Phone number is required';
    if (!values.email) errors.email = 'Email is required';
    if (!values.notes) errors.notes = 'Message is required';
    return errors;
  };

  const handleValueChange = (newValue) => {
    setDateRange(newValue);
  };

  const handleInputChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const errors = validate(formValues);
    if (Object.keys(errors).length === 0) {
      const values = { ...formValues, availableTime: dateRange };
      let response = await PostRequest('/messages/appointment/create', values);
      if (response && response.status === 0 && response.success) {
        setStatus(true);
        setFormValues({});
        setTimeout(() => setStatus(false), 10000);
      }
    } else {
      setFormErrors(errors);
    }
  };

  return (
    <div className="bg-gray-100">
      <RHeader />
      <h2 className="md:text-[65px] text-2xl font-bold text-center mt-20 text-gray-900">Book a Consultation</h2>
      <div className="container mx-auto my-5 p-10 bg-white shadow rounded-2xl mt-32">
        <h4 className="text-center pt-5 pb-3 text-gray-900 md:text-2xl text-xl">
          Fill all information to book meeting with us
        </h4>
        <form autoComplete="on" onSubmit={handleFormSubmit}>
          {/* Step 1 */}
          <h3 className="text-xl font-semibold my-3">Step 1</h3>
          <div className="grid gap-6 mb-6 md:grid-cols-2">
            {/* Service Type */}
            <div className="mt-5">
              <label htmlFor="serviceType" className="block text-gray-700 text-sm font-bold mb-2">Please Select Service:</label>
              <select id="serviceType" name="serviceType" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" onChange={handleInputChange} value={formValues.serviceType || ''}>
                <option value="Initial call">Initial Call</option>
                <option value="Follow up call">Follow Up Call</option>
              </select>
            </div>
            {/* Datepicker */}
            <div className="mb-4 mt-5">
              <label className="block text-gray-700 text-sm font-bold mb-2">I'm available on or after:</label>
              <Datepicker  value={dateRange} primaryColor={"#138DFF"} onChange={handleValueChange} inputClassName="relative transition-all duration-300 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-2.5" />
            </div>
          </div>

          {/* Step 2 */}
          <h3 className="text-xl font-semibold my-3">Step 2</h3>
          <div className="grid gap-6 mb-6 md:grid-cols-2">
            {/* Full Name */}
            <div>
              <label htmlFor="fullName" className="block mb-2 text-sm font-medium text-gray-900">Full name</label>
              <input type="text" id="fullName" name="fullName" onChange={handleInputChange} value={formValues.fullName || ''} placeholder="John Doe" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
              {formErrors.fullName && <p className="text-red-500 text-xs mt-1">{formErrors.fullName}</p>}
            </div>
            {/* Phone Number */}
            <div>
              <label htmlFor="phone" className="block mb-2 text-sm font-medium text-gray-900">Phone number</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                onChange={handleInputChange}
                value={formValues.phone || ''}
                placeholder="+447188748773"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                pattern="(\+?\d{1,4}[-.\s]?|\(\d{1,4}\)[-.\s]?)?\(?\d{1,4}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,9}"
              />
              {formErrors.phone && <p className="text-red-500 text-xs mt-1">{formErrors.phone}</p>}
            </div>

          </div>
          {/* Email Address */}
          <div className="mb-6">
            <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900">Email address</label>
            <input name="email" onChange={handleInputChange} value={formValues.email || ''} placeholder="johndoe@domain.com" type="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
            {formErrors.email && <p className="text-red-500 text-xs mt-1">{formErrors.email}</p>}
          </div>
          {/* Additional Notes */}
          <label htmlFor="notes" className="block mb-2 text-sm font-medium text-gray-900">Your message</label>
          <textarea name="notes" onChange={handleInputChange} value={formValues.notes || ''} rows="4" className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Write your thoughts here..."></textarea>
          {formErrors.notes && <p className="text-red-500 text-xs mt-1">{formErrors.notes}</p>}
          {/* Success Status */}
          {status && <div className="text-green-500">Appointment successfully booked!</div>}

          {/* Submit Button */}
          <div className="flex items-center justify-center pt-5 mt-5 mb-5">
            <DefaultButton type="primary" label="Book a Consultation" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default BookConsultationComponent;