import RFooter from '../shared/RFooter';
import RHero from '../shared/RHero';
import whatClientsSays from "../assets/images/what-client-says-bg.svg";
import StepsCard from './StepsCard';
import BusinessCard from './BusinessCard';
import IndustryWeServe from './IndustriesWeServe';
import WhyChooseRightBox from './WhyChooseRightBox';

import BespokeSolutionsComponent from './BespokeSolutionComponent';
import { NavLink } from 'react-router-dom';
import RightBoxTestimonialComponent from './RightboxTestimonialComponent';

function HomePage() {

    const steps = [
        {
            step: 1,
            title: "Discover Your Needs",
            description: "Tell  us about your products and packaging goals. We'll help you identify the perfect box size, material, and features."
        },
        {
            step: 2,
            title: "Design Your Solution",
            description: "Our experienced design team will create a solution that suits your unique requirements."
        },
        {
            step: 3,
            title: "Develop Your Sample",
            description: "Get a physical sample of your box before placing a full order. We offer fast turnaround times on sample development."
        },
        {
            step: 4,
            title: "Deliver Your Products",
            description: "Enjoy reliable and timely delivery of your high-quality cardboard boxes. We take pride in efficient logistics."
        },
        {
            step: 5,
            title: "Dedicated Support",
            description: "Our experienced team are available throughout your journey. We're here to answer questions and ensure a smooth experience."
        }
    ];


    return (
        <div className="bg-gradient-to-br overflow-hidden  bg-gradient-default">
            <RHero />
            <svg width="346" height="524" viewBox="0 0 346 524" fill="none" xmlns="http://www.w3.org/2000/svg" className='absolute hidden md:block mt-22 right-0 w-36 mix-blend-soft-light mt-48'>
                <rect x="451.805" y="-29" width="142.849" height="679.961" rx="71.4245" transform="rotate(45 451.805 -29)" fill="#E2ECFF" />
            </svg>
            <svg width="317" height="524" viewBox="0 0 317 524" fill="none" xmlns="http://www.w3.org/2000/svg" className='absolute hidden md:block mt-22 right-0 w-36 mix-blend-soft-light mt-80'>
                <rect x="451.375" y="-29.0117" width="142.849" height="679.961" rx="71.4245" transform="rotate(45 451.375 -29.0117)" fill="#E2ECFF" />
            </svg>
            <section className="py-8 container text-center mx-auto">
                <p className='text-xl md:text-4xl font-open-sans font-bold mb-8 text-center md:px-0 px-20'>Find the <span className='text-blue-500'>RightBox</span> in 5 simple steps</p>
                <div className="flex flex-wrap flex-grow gap-4 justify-center items-center">
                    {
                        steps.map((step, index) => <StepsCard {...step} key={index} />)
                    }
                </div>
            </section>
            <svg width="224" height="524" viewBox="0 0 224 524" fill="none" xmlns="http://www.w3.org/2000/svg" className='absolute fill-current hidden mix-blend-soft-light md:block h-64 mt-32'>
                <rect x="152.375" y="-29.0117" width="142.849" height="679.961" rx="71.4245" transform="rotate(45 152.375 -29.0117)" fill="#E2ECFF" />
            </svg>
            <svg width="224" height="524" viewBox="0 0 224 524" fill="none" xmlns="http://www.w3.org/2000/svg" className='absolute hidden md:block mix-blend-soft-light fill-current h-64'>
                <rect x="152.375" y="-29.0117" width="142.849" height="679.961" rx="71.4245" transform="rotate(45 152.375 -29.0117)" fill="#E2ECFF" />
            </svg>
            <IndustryWeServe />
            <div className='md:px-10'>
                <h2 className="text-center px-3 text-[#212640] font-open-sans font-bold md:text-4xl tracking-wide md:leading-16">
                    Bespoke Solutions {' '}
                    <span className="text-[#138DFF] font-feature-normal md:whitespace-nowrap block md:inline">
                        Tailored To Your Needs
                    </span>
                </h2>
                <p className='text-center text-[#47475A] font-manrope font-normal md:leading-[32px] leading-relaxed md:text-lg md:px-64 px-3'>
                    Explore our bespoke range of protective packaging solutions manufactured for every product and every need.
                </p>
                <BespokeSolutionsComponent />
                <div className="px-6 md:py-20 py-10 md:mb-10 text-center">
                    <NavLink to="/products" className="mx-auto bg-[#138DFF] text-white font-normal py-2 px-4 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-300 focus:border-blue-300 transition-transform transform hover:scale-105">
                        Learn More
                    </NavLink>
                </div>
            </div>
            <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="138" height="182" viewBox="0 0 138 182" fill="none" className='absolute left-10 z-20 w-32 h-32 ml-6 md:-mt-16 hidden md:block fill-current'>
                    <path d="M3.00005 6.00043C4.65691 6.00043 6.00005 4.65729 6.00005 3.00044C6.00005 1.34359 4.65691 0.000444267 3.00005 0.000444267C1.3432 0.000444267 5.55351e-05 1.34359 5.55351e-05 3.00044C5.55351e-05 4.65729 1.3432 6.00043 3.00005 6.00043Z" fill="#138DFF" />
                    <path d="M3 49.9996C4.65685 49.9996 6 48.6564 6 46.9996C6 45.3427 4.65685 43.9996 3 43.9996C1.34315 43.9996 0 45.3427 0 46.9996C0 48.6564 1.34315 49.9996 3 49.9996Z" fill="#69769C" />
                    <path d="M3 94C4.65685 94 6 92.6568 6 91C6 89.3431 4.65685 88 3 88C1.34315 88 0 89.3431 0 91C0 92.6568 1.34315 94 3 94Z" fill="#69769C" />
                    <path d="M3 138C4.65685 138 6 136.656 6 135C6 133.343 4.65685 132 3 132C1.34315 132 0 133.343 0 135C0 136.656 1.34315 138 3 138Z" fill="#69769C" />
                    <path d="M3 182C4.65685 182 6 180.657 6 179C6 177.343 4.65685 176 3 176C1.34315 176 0 177.343 0 179C0 180.657 1.34315 182 3 182Z" fill="#69769C" />
                    <path d="M47 5.99999C48.6568 5.99999 50 4.65685 50 2.99999C50 1.34314 48.6568 0 47 0C45.3431 0 44 1.34314 44 2.99999C44 4.65685 45.3431 5.99999 47 5.99999Z" fill="#138DFF" />
                    <path d="M47 49.9996C48.6568 49.9996 50 48.6564 50 46.9996C50 45.3427 48.6568 43.9996 47 43.9996C45.3431 43.9996 44 45.3427 44 46.9996C44 48.6564 45.3431 49.9996 47 49.9996Z" fill="#69769C" />
                    <path d="M47 94C48.6568 94 50 92.6568 50 91C50 89.3431 48.6568 88 47 88C45.3431 88 44 89.3431 44 91C44 92.6568 45.3431 94 47 94Z" fill="#69769C" />
                    <path d="M47 138C48.6568 138 50 136.656 50 135C50 133.343 48.6568 132 47 132C45.3431 132 44 133.343 44 135C44 136.656 45.3431 138 47 138Z" fill="#69769C" />
                    <path d="M47 182C48.6568 182 50 180.657 50 179C50 177.343 48.6568 176 47 176C45.3431 176 44 177.343 44 179C44 180.657 45.3431 182 47 182Z" fill="#69769C" />
                    <path d="M91 5.99999C92.6568 5.99999 94 4.65685 94 2.99999C94 1.34314 92.6568 0 91 0C89.3431 0 88 1.34314 88 2.99999C88 4.65685 89.3431 5.99999 91 5.99999Z" fill="#138DFF" />
                    <path d="M91 49.9996C92.6568 49.9996 94 48.6564 94 46.9996C94 45.3427 92.6568 43.9996 91 43.9996C89.3431 43.9996 88 45.3427 88 46.9996C88 48.6564 89.3431 49.9996 91 49.9996Z" fill="#69769C" />
                    <path d="M91 94C92.6568 94 94 92.6568 94 91C94 89.3431 92.6568 88 91 88C89.3431 88 88 89.3431 88 91C88 92.6568 89.3431 94 91 94Z" fill="#69769C" />
                    <path d="M91 138C92.6568 138 94 136.656 94 135C94 133.343 92.6568 132 91 132C89.3431 132 88 133.343 88 135C88 136.656 89.3431 138 91 138Z" fill="#69769C" />
                    <path d="M91 182C92.6568 182 94 180.657 94 179C94 177.343 92.6568 176 91 176C89.3431 176 88 177.343 88 179C88 180.657 89.3431 182 91 182Z" fill="#69769C" />
                    <path d="M135 5.99999C136.657 5.99999 138 4.65685 138 2.99999C138 1.34314 136.657 0 135 0C133.343 0 132 1.34314 132 2.99999C132 4.65685 133.343 5.99999 135 5.99999Z" fill="#138DFF" />
                    <path d="M135 49.9996C136.657 49.9996 138 48.6564 138 46.9996C138 45.3427 136.657 43.9996 135 43.9996C133.343 43.9996 132 45.3427 132 46.9996C132 48.6564 133.343 49.9996 135 49.9996Z" fill="#69769C" />
                    <path d="M135 94C136.657 94 138 92.6568 138 91C138 89.3431 136.657 88 135 88C133.343 88 132 89.3431 132 91C132 92.6568 133.343 94 135 94Z" fill="#69769C" />
                    <path d="M135 138C136.657 138 138 136.656 138 135C138 133.343 136.657 132 135 132C133.343 132 132 133.343 132 135C132 136.656 133.343 138 135 138Z" fill="#69769C" />
                    <path d="M135 182C136.657 182 138 180.657 138 179C138 177.343 136.657 176 135 176C133.343 176 132 177.343 132 179C132 180.657 133.343 182 135 182Z" fill="#69769C" />
                </svg>
                <div className="relative isolate bg-gray-900 md:py-10 py-8  lg:h-[550px] h-full md:px-36 sm:px-0" >
                    <img
                        src="https://s3-alpha-sig.figma.com/img/d5fb/af7a/77964d7d744a7536cabeb662d4097335?Expires=1698624000&Signature=CCC4pGEWQ9uBUe7m~qeUEmzRualy--xGboUrNYUPvViBKv6bWKfc9H6aj7UDycmCIYs8obp2thJ0TS21tfFSVqWQMNsfKsy3KS-i5QIiLpqoZlaQsCl26Ps1EPVP0q4rbtQG5GAhAAWKeheANwdN6iMBHigFJqiI7sEBS12re~ZojdFZqO4KY84JHqPSWx1LoKolM~oPT1PG12YEkBb5i-Dvbuzl2WjdcMz9m6TvA6TFwmHlf~9Wqwi~rlfRHAy9yJH2UFkkMsHrJ30Rk-IwmpYszcyvjItFa-GqnVbkKycnG5YqFwGdug2EHX97PmYlXddB8gOkAPu1oXH8ViXAaA__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4"
                        alt=""
                        className="absolute mix-blend-multiply grayscale bg-[#232636] inset-0 -z-10 h-full w-full object-cover object-center md:object-center"
                    />

                    <WhyChooseRightBox />
                </div>
            </div>
            <div className='text-center bg-cover bg-no-repeat md:pt-[17em] pt-10 pb-10' style={{ backgroundImage: `url(${whatClientsSays})` }}>
                <RightBoxTestimonialComponent />
            </div>
            <div className='container relative md:px-[6%] mx-auto'>
                <BusinessCard />
            </div>
            <RFooter />
        </div >
    );
}
export default HomePage;
