import React, { lazy } from 'react';
import NotFoundPage from './components/NotFoundPage';
import HomePage from './components/RHomePage';
import { createBrowserRouter } from "react-router-dom";
import BookConsultationComponent from "./components/BookConsultationPage";
import ErrorPage from './shared/ErrorPage';
// const AboutSupplierPageComponent = lazy(() => import("./components/AboutSupplier"));
const BlogPageComponent = lazy(() => import("./components/BlogPage"));
// const BecomeSupplierComponent = lazy(() => import("./components/BecomeSupplierPage"));
// const SendCostPackingComponent = lazy(() => import("./components/SendCostPackingPage"));
const OrderPage = lazy(() => import("./components/OrderPage"));
// const BlogPostDetails = lazy(() => import("./shared/BlogPostDetails"));
const ProductPage = lazy(() => import('./components/ProductPage'));
const ServicesPage = lazy(() => import('./components/ServicesPage'));
const SustainabilityPage = lazy(() => import('./components/SustainabilityPage'));

export const router = (
    createBrowserRouter([
        { path: "/", element: <HomePage />, errorElement: <ErrorPage /> },
        { path: "/booking", caseSensitive: true, element: <BookConsultationComponent />, errorElement: <ErrorPage /> },
        // { path: "/about-supplier", caseSensitive: true, element: <AboutSupplierPageComponent /> },
        // { path: "/send-cost-packing", caseSensitive: true, element: <SendCostPackingComponent /> },
        { path: "/products", caseSensitive: true, element: <ProductPage />, errorElement: <ErrorPage /> },
        { path: "/services", caseSensitive: true, element: <ServicesPage />, errorElement: <ErrorPage /> },
        { path: "/sustainability", caseSensitive: true, element: <SustainabilityPage />, errorElement: <ErrorPage /> },
        { path: "/request", caseSensitive: true, element: <OrderPage />, errorElement: <ErrorPage /> },
        { path: "/blog", element: <BlogPageComponent />, errorElement: <ErrorPage /> },
        // { path: "/become-supplier", caseSensitive: true, element: <BecomeSupplierComponent /> },
        // { path: "/post/:postId", caseSensitive: true, element: <BlogPostDetails /> },
        { path: "*", element: <NotFoundPage /> },
    ])
);
