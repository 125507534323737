import { Fragment } from 'react'
import { NavLink } from "react-router-dom";
import { Disclosure, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import logo from "../assets/images/logo.svg";
const navigation = [
    {
        name: 'Products',
        href: '/products'
    },
    {
        name: 'Services',
        href: '/services',
    },
    {
        name: 'Sustainability',
        href: '/sustainability',
    },
];

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export default function RHeader({ newPage = true }) {
    return (
        <Disclosure as="nav" className={classNames(
            newPage ? "bg-[#0E2A42]" : "md:bg-transparent bg-slate-50",
            "py-3 z-10 relative"
        )}>
            {({ open }) => (
                <>
                    <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 z-10">
                        <div className="relative flex h-16 items-center justify-between align-middle">
                            <div className="absolute inset-y-0 right-0 flex items-center sm:hidden">
                                <NavLink to="/request">
                                    <button
                                        className="sm:hidden px-4 py-2 bg-[#138DFF] mr-2 rounded-3xl border-blue-500 text-white transition duration-300 flex items-center space-x-2"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth={1.5}
                                            stroke="currentColor"
                                            className="w-4 h-4"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                                            />
                                        </svg>
                                        <span className="text-white font-montserrat text-base font-semibold tracking-wide uppercase">
                                            Box Builder
                                        </span>
                                    </button>
                                </NavLink>
                                <Disclosure.Button className="sm:hidden rounded-md px-2 py-2 bg-gray-800  text-white transition duration-300 flex items-center space-x-2">
                                    <span className="sr-only">Open main menu</span>
                                    {open ? (
                                        <XMarkIcon className="block h-8 w-8" aria-hidden="true" />
                                    ) : (
                                        <Bars3Icon className="block h-8 w-8" aria-hidden="true" />
                                    )}
                                </Disclosure.Button>
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Disclosure.Panel className="absolute right-0 z-20 top-16 w-48 origin-top-right px-5 rounded-2xl  bg-gray-800 py-5 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        {navigation.map((item) => (
                                            <NavLink key={item.name}
                                                aria-current={item.current ? 'page' : undefined}
                                                to={item.href}
                                                className={({ isActive }) =>
                                                    classNames(
                                                        isActive ? "text-blue-500 font-bold" : "text-white",
                                                        'py-3 hover:text-blue-500 font-montserrat text-base font-semibold tracking-wide uppercase block'
                                                    )
                                                }
                                            >
                                                {item.name}
                                            </NavLink>
                                        ))}
                                    </Disclosure.Panel>
                                </Transition>
                            </div>
                            <div className="flex flex-shrink-0 items-center md:mt-1 md:ps-20 ps-4">
                                <NavLink to="/">
                                    <img
                                        className="md:h-18 w-auto  h-16"
                                        src={logo}
                                        alt="right box logo"
                                    />
                                </NavLink>
                            </div>
                            <div className={classNames(
                                newPage ? "md:pt-2" : "md:pt-10",
                                "flex items-center justify-around md:pe-36 space-x-28"
                            )}>
                                <div className="hidden sm:ml-6 sm:block">
                                    <div className="flex space-x-4">
                                        {navigation.map((item) => (
                                            <NavLink key={item.name}
                                                exact={item.href.toString()}
                                                to={item.href}
                                                aria-current={item.current ? 'page' : undefined}
                                                className={({ isActive }) =>
                                                    classNames(
                                                        isActive ? "text-blue-500 font-bold" : "text-white",
                                                        'py-3 hover:text-blue-500 font-montserrat text-base font-semibold tracking-wide uppercase'
                                                    )
                                                }
                                            >
                                                {item.name}
                                            </NavLink>
                                        ))}

                                    </div>
                                </div>

                                <div className='hidden md:block'>
                                    <NavLink to="/request">
                                        <button
                                            className="px-4 bg-[#138DFF] rounded-3xl hover:border-blue-500 text-white transition duration-300 flex items-center space-x-2 mt-1"
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="35" className="w-4 h-4" height="41" viewBox="0 0 35 41" fill="none">
                                                <path d="M17.4987 22.2266V39.7228L5.17188 35.0093V24.4486" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M17.4973 22.2252L5.29398 17.5117L1.84375 22.5243L14.0471 27.2377L17.4973 22.2252Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M17.5 22.2266V39.7228L29.8269 35.0093V24.5271" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M17.5 22.2252L29.7034 17.5117L33.1536 22.5243L20.9502 27.2377L17.5 22.2252Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M5.29688 17.5103L17.5002 12.7969L29.7036 17.5103" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M17.503 8.83768L15.8543 9.42377C15.1706 9.66682 14.4559 9.14775 14.4759 8.4226L14.5241 6.67393L13.457 5.28752C13.0145 4.7126 13.2875 3.87268 13.9835 3.6676L15.662 3.17299L16.6512 1.73002C17.0614 1.13166 17.9448 1.13166 18.3549 1.73002L19.3441 3.17299L21.0226 3.6676C21.7186 3.87275 21.9916 4.7126 21.5491 5.28752L20.482 6.67393L20.5302 8.4226C20.5501 9.14775 19.8355 9.66682 19.1518 9.42377L17.503 8.83768Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M28.7138 12.2219L27.5239 12.6449C26.8402 12.8879 26.1255 12.3688 26.1455 11.6437L26.1802 10.3817L25.4102 9.38111C24.9677 8.80619 25.2406 7.96627 25.9366 7.76119L27.148 7.40424L27.8619 6.36283C28.2721 5.76447 29.1555 5.76447 29.5656 6.36283L30.2795 7.40424L31.4909 7.76119C32.1869 7.96635 32.4598 8.80619 32.0173 9.38111L31.2473 10.3817L31.282 11.6437C31.3019 12.3688 30.5873 12.8879 29.9036 12.6449L28.7138 12.2219Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M6.28406 12.2219L5.09422 12.6449C4.41055 12.8879 3.69586 12.3688 3.71586 11.6437L3.75055 10.3817L2.98047 9.38111C2.53797 8.80619 2.81094 7.96627 3.50695 7.76119L4.71828 7.40424L5.43219 6.36283C5.84242 5.76447 6.72578 5.76447 7.13594 6.36283L7.84985 7.40424L9.06117 7.76119C9.75719 7.96635 10.0302 8.80619 9.58766 9.38111L8.81758 10.3817L8.85227 11.6437C8.87219 12.3688 8.15758 12.8879 7.47391 12.6449L6.28406 12.2219Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            <span className="py-1.5 text-white hover:text-white font-montserrat text-base font-semibold tracking-wide uppercase">
                                                Box Builder
                                            </span>
                                        </button>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </Disclosure>
    )
}