const FluidButton = ({ label, type, onClick, icon, htmlType }) => {
  let buttonClasses = "px-4 py-3 text-white text-xl font-semibold rounded-md focus:outline-none";

  if (type === 'primary') {
    buttonClasses += " bg-blue-500 hover:bg-[#2190f5]";
  } else if (type === 'secondary') {
    buttonClasses += " bg-gray-500 hover:bg-gray-600";
  }

  return (
    <button
      className={`${buttonClasses}  w-full flex items-center justify-center`}
      type={htmlType || 'button'}
      onClick={onClick}
    >
      {icon && <span className="mr-2">{icon}</span>}
      {label}
    </button>
  );
};

export default FluidButton;
