export default function TestimonialCard(card) {
    return (
        <div className='keen-slider__slide'>
            <div className="group w-full mt-12 bg-white font-open-sans relative flex flex-col items-center cursor-pointer md:shadow-md shadow-lg py-10  px-12 rounded-r-2xl rounded-tl-2xl overflow-visible md:min-h-[200px]">
                <svg xmlns="http://www.w3.org/2000/svg" width="54" height="37" viewBox="0 0 54 37" fill="none" className='absolute -top-2 left-10 z-20'>
                    <path fillRule="evenodd" clipRule="evenodd" d="M0.829102 21.718V36.5H22.7635V16.5H14.0122C14.0122 12.078 17.4464 6.636 22.7921 4.802L20.6037 0.5C9.06413 2.578 0.829102 10.31 0.829102 21.718ZM31.6258 21.718V36.5H53.5602V16.5H44.7715C44.7715 12.078 48.2057 6.636 53.5493 4.802L51.3631 0.5C39.8235 2.578 31.6258 10.31 31.6258 21.718Z" fill="#3A76E5" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M0.829102 21.718V36.5H22.7635V16.5H14.0122C14.0122 12.078 17.4464 6.636 22.7921 4.802L20.6037 0.5C9.06413 2.578 0.829102 10.31 0.829102 21.718ZM31.6258 21.718V36.5H53.5602V16.5H44.7715C44.7715 12.078 48.2057 6.636 53.5493 4.802L51.3631 0.5C39.8235 2.578 31.6258 10.31 31.6258 21.718Z" fill="#138DFF" />
                </svg>
                <div className="text-gray-600 flex flex-col items-center relative z-10">
                    <p className="xl:w-90 text-base leading-normal text-start mt-4">{card.statement}</p>
                </div>
                <div className="text-white absolute bottom-0 mb-2 left-0 z-10">
                    <svg width="30" height="30" viewBox="0 0 30 30" className='absolute rotate-180 '>
                        <polygon points="0,30 30,30 30,0" fill="currentColor" />
                    </svg>
                </div>
            </div>
            <div className="flex flex-col items-center justify-center mt-10">
                <p className="text-base font-semibold leading-4 my-2 text-gray-800">{card.role}</p>
                <p className="text-base leading-4 text-center text-gray-600">{card.company}</p>
            </div>
        </div>
    )
}
