export const PostRequest = async (
  url,
  params = {},
  secure = false,
  ...others
) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}${url}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    }).catch((error) => {
      console.error("Error:", error);
    });

    return response.json();
  } catch (error) {
    console.log(error);
  }
};

export const GetRequest = async (url, secure = false) => {
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).catch((error) => {
      console.error("Error:", error);
    });

    return response.json();
  } catch (error) {
    console.log(error);
  }
};
