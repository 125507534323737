import React, { useState } from 'react';
import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';
import TestimonialCard from './TestimonialCard';

const RightBoxTestimonialComponent = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);

  const testimonials = [
    {
      name: "John Doe",
      statement: "When we approached RightBox, we wanted to increase the security of products during shipping.They offered a postal box with a tear strip opening that not only secured our products but also improved the unboxing experience for our customers.",
      role: "Supply chain manager",
      company: "Subscription box company"
    },
    {
      name: "Jane Smith",
      statement: "Rightbox’s crash lock boxes have been a game-changer for our warehouse. The ease of assembly saves us time, boosting packing speed which is vital for our industry.Previously, packing our goods was a constant bottleneck in our warehouse.",
      role: "Warehouse Manager",
      company: "FMCG"
    },
    {
      name: "Alice Johnson",
      statement: "We needed a solution that could handle the bumps and spills of our supply chain.Their boxes helped protect our bottles and showcase our brand beautifully.We've seen a significant decrease in damage during shipping and are happy to haveRightBox on board as our packaging supplier.",
      role: "Warehouse manager",
      company: "Alcohol distributor"
    },
    {
      name: "Nazeh Abel",
      statement: "We were using stock boxes to ship our products, not realising the hidden costs from wasted space.RightBox designed bespoke boxes that are unique to our products.We’ve seen a large reduction in our unit and shipping costs.Very happy with the product and service from RightBox!",
      role: "Purchasing manager",
      company: "Plastics sector"
    },
    {
      name: "Nazeh Abel",
      statement: "Our packaging ranges from pallet boxes to high quality print retail boxes. We had experienced rising costs and lead times of up to 16 weeks which made planning a nightmare.Working with RightBox has reduced our annual spend by 40% and their shorter lead times means we haven’t got to worry about running out of boxes.",
      role: "Purchasing manager",
      company: "Automotive parts"
    },
    {
      name: "Nazeh Abel",
      statement: "Switching to RightBox was a great move! Fantastic service and guidance, competitive on pricing and fast delivery.What stood out was their close attention to detail and communication, right from our initial conversation through to delivery and beyond.",
      role: "Purchasing manager",
      company: "Automotive parts"
    }
  ];

  const [sliderRef, instanceRef] = useKeenSlider({

    breakpoints: {
      "(min-width: 640px)": {
        slides: { perView: 1, spacing: 10 },
      },
      "(min-width: 1000px)": {
        slides: { perView: 2, spacing: 15 },
      },
    },
    slides: { perView: 1, centered: true, spacing: 15 },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel)
    },
    created() {
      setLoaded(true)
    },
    loop: true,
    renderMode: 'performance',
    mode: "free",
    initial: 0,
  })

  const handlePrev = () => {
    if (instanceRef.current) {
      instanceRef.current.prev();
    }
  };

  const handleNext = () => {
    if (instanceRef.current) {
      instanceRef.current.next();
    }
  };

  const classNames = (...classes) => {
    return classes.filter(Boolean).join(' ');
  }
  return (
    <div className='pt-20 md:pt-[10em]'>
      <h4 className='uppercase text-blue-500'>testimonials</h4>
      <h3 className='text-center font-open-sans font-extrabold text-3xl leading-tight text-black-900 md:text-black-800 lg:text-black-700 xl:text-5xl xl:leading-[64px] sm:text-xl sm:leading-normal sm:text-black-700 mb-20'>
        What Our Clients Say About Us
      </h3>
      <div className="container relative mx-auto md:px-40 px-8">
        <svg xmlns="http://www.w3.org/2000/svg" width="138" height="182" viewBox="0 0 138 182" fill="none" className='absolute hidden  md:block right-[10%] z-0 w-32 h-32 mt-10 fill-current'>
          <path d="M3.00005 6.00043C4.65691 6.00043 6.00005 4.65729 6.00005 3.00044C6.00005 1.34359 4.65691 0.000444267 3.00005 0.000444267C1.3432 0.000444267 5.55351e-05 1.34359 5.55351e-05 3.00044C5.55351e-05 4.65729 1.3432 6.00043 3.00005 6.00043Z" fill="#138DFF" />
          <path d="M3 49.9996C4.65685 49.9996 6 48.6564 6 46.9996C6 45.3427 4.65685 43.9996 3 43.9996C1.34315 43.9996 0 45.3427 0 46.9996C0 48.6564 1.34315 49.9996 3 49.9996Z" fill="#69769C" />
          <path d="M3 94C4.65685 94 6 92.6568 6 91C6 89.3431 4.65685 88 3 88C1.34315 88 0 89.3431 0 91C0 92.6568 1.34315 94 3 94Z" fill="#69769C" />
          <path d="M3 138C4.65685 138 6 136.656 6 135C6 133.343 4.65685 132 3 132C1.34315 132 0 133.343 0 135C0 136.656 1.34315 138 3 138Z" fill="#69769C" />
          <path d="M3 182C4.65685 182 6 180.657 6 179C6 177.343 4.65685 176 3 176C1.34315 176 0 177.343 0 179C0 180.657 1.34315 182 3 182Z" fill="#69769C" />
          <path d="M47 5.99999C48.6568 5.99999 50 4.65685 50 2.99999C50 1.34314 48.6568 0 47 0C45.3431 0 44 1.34314 44 2.99999C44 4.65685 45.3431 5.99999 47 5.99999Z" fill="#138DFF" />
          <path d="M47 49.9996C48.6568 49.9996 50 48.6564 50 46.9996C50 45.3427 48.6568 43.9996 47 43.9996C45.3431 43.9996 44 45.3427 44 46.9996C44 48.6564 45.3431 49.9996 47 49.9996Z" fill="#69769C" />
          <path d="M47 94C48.6568 94 50 92.6568 50 91C50 89.3431 48.6568 88 47 88C45.3431 88 44 89.3431 44 91C44 92.6568 45.3431 94 47 94Z" fill="#69769C" />
          <path d="M47 138C48.6568 138 50 136.656 50 135C50 133.343 48.6568 132 47 132C45.3431 132 44 133.343 44 135C44 136.656 45.3431 138 47 138Z" fill="#69769C" />
          <path d="M47 182C48.6568 182 50 180.657 50 179C50 177.343 48.6568 176 47 176C45.3431 176 44 177.343 44 179C44 180.657 45.3431 182 47 182Z" fill="#69769C" />
          <path d="M91 5.99999C92.6568 5.99999 94 4.65685 94 2.99999C94 1.34314 92.6568 0 91 0C89.3431 0 88 1.34314 88 2.99999C88 4.65685 89.3431 5.99999 91 5.99999Z" fill="#138DFF" />
          <path d="M91 49.9996C92.6568 49.9996 94 48.6564 94 46.9996C94 45.3427 92.6568 43.9996 91 43.9996C89.3431 43.9996 88 45.3427 88 46.9996C88 48.6564 89.3431 49.9996 91 49.9996Z" fill="#69769C" />
          <path d="M91 94C92.6568 94 94 92.6568 94 91C94 89.3431 92.6568 88 91 88C89.3431 88 88 89.3431 88 91C88 92.6568 89.3431 94 91 94Z" fill="#69769C" />
          <path d="M91 138C92.6568 138 94 136.656 94 135C94 133.343 92.6568 132 91 132C89.3431 132 88 133.343 88 135C88 136.656 89.3431 138 91 138Z" fill="#69769C" />
          <path d="M91 182C92.6568 182 94 180.657 94 179C94 177.343 92.6568 176 91 176C89.3431 176 88 177.343 88 179C88 180.657 89.3431 182 91 182Z" fill="#69769C" />
          <path d="M135 5.99999C136.657 5.99999 138 4.65685 138 2.99999C138 1.34314 136.657 0 135 0C133.343 0 132 1.34314 132 2.99999C132 4.65685 133.343 5.99999 135 5.99999Z" fill="#138DFF" />
          <path d="M135 49.9996C136.657 49.9996 138 48.6564 138 46.9996C138 45.3427 136.657 43.9996 135 43.9996C133.343 43.9996 132 45.3427 132 46.9996C132 48.6564 133.343 49.9996 135 49.9996Z" fill="#69769C" />
          <path d="M135 94C136.657 94 138 92.6568 138 91C138 89.3431 136.657 88 135 88C133.343 88 132 89.3431 132 91C132 92.6568 133.343 94 135 94Z" fill="#69769C" />
          <path d="M135 138C136.657 138 138 136.656 138 135C138 133.343 136.657 132 135 132C133.343 132 132 133.343 132 135C132 136.656 133.343 138 135 138Z" fill="#69769C" />
          <path d="M135 182C136.657 182 138 180.657 138 179C138 177.343 136.657 176 135 176C133.343 176 132 177.343 132 179C132 180.657 133.343 182 135 182Z" fill="#69769C" />
        </svg>
        <div ref={sliderRef} className="keen-slider mb-20 mt-10 ">
          {testimonials.map((testimonial, index) => (
            <TestimonialCard {...testimonial} key={index} />
          ))}
        </div>
        {loaded && instanceRef.current && (<div className='flex justify-center w-full items-center relative text-center mb-10 '>
          <button disabled={currentSlide === 0} className={classNames(
            currentSlide === 0 ? "bg-white" : "bg-blue-500", "rounded-md focus:outline-none border-blue-300 hover:border-blue-400 text-gray-600 hover:bg-blue-500 focus-visible:outline-2 align-middle w-1 font-bold py-2 px-4 z-10 mr-4")} onClick={handlePrev} >
            <svg className="w-6 h-6 -ml-3" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
              <path d="M17.3452 11H4.66406" stroke="#A4A4A4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M11.0046 17.3452L4.66406 11.0046L11.0046 4.66406" stroke="#A4A4A4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </button>
          <button disabled={currentSlide === instanceRef.current.track.details.slides.length - 1} className={classNames(
            currentSlide === instanceRef.current.track.details.slides.length - 1 ? "bg-white" : "bg-blue-500", "hover:bg-blue-700 w-1 text-gray-600 font-bold py-2 px-4 z-10 rounded-md focus:outline-none border-blue-300 hover:border-blue-400 focus-visible:outline-2 align-middle")} onClick={handleNext}>
            <svg className="w-6 h-6 -ml-3" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
              <path d="M4.66406 11H17.3452" stroke="#A4A4A4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M11 4.66406L17.3406 11.0046L11 17.3452" stroke="#A4A4A4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </button>
        </div>)}
      </div>
    </div>
  );
};

export default RightBoxTestimonialComponent;